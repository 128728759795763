import { NavigationItem } from "../../../../constants";
import {
  COACH_BRANDED_APP_ROUTE,
  COACH_CLIENT_PORTAL_ROUTE,
  COACH_INTEGRATION_ROUTE,
  COACH_PLAN_ROUTE,
  COACH_PROFILE_ROUTE,
  COACH_THEME_ROUTE,
} from "../../../routes";

export const coachSettingsNavigation = (
  subscribed: boolean,
  branded?: boolean,
): NavigationItem[] => [
  {
    name: "Profile",
    link: COACH_PROFILE_ROUTE,
  },
  {
    name: "Client portal",
    link: COACH_CLIENT_PORTAL_ROUTE,
  },
  {
    name: "Theme",
    link: COACH_THEME_ROUTE,
  },
  ...(branded
    ? [
        {
          name: "Branded app",
          link: COACH_BRANDED_APP_ROUTE,
        },
      ]
    : []),
  {
    name: "Integration",
    link: COACH_INTEGRATION_ROUTE,
  },
];
