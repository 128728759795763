import { ComponentType, RowType } from "../../constants";
import { defaultComponentTitles } from "../../utils/component";
import { WorkoutSection } from "../workout/types";
import { defaultExerciseTitle, defaultSectionTitle } from "../workout/utils";
import { chunk as chunkArray, isEqual } from "lodash";

export enum ColumnField {
  DRAG = "drag",
  ICON = "icon",
  EXERCISE = "exercise",
  SETS = "sets",
  REPS = "reps",
  WEIGHT = "weight",
  EXTRA_MEASUREMENT = "extraMeasurement",
  ACTIONS = "actions",
}

export interface CustomProgramCellEditorProps {
  handleExerciseUpdate: (
    workout: WorkoutSection,
    content?: any,
    wsId?: any,
    id?: any,
    onCompleteCallback?: () => void,
  ) => void;
  setExerciseDrawerData: React.Dispatch<
    React.SetStateAction<{
      componentId: any;
      componentContent: any;
      workoutSection: any;
      exercise: any;
    }>
  >;
  paramsData: any;
}

export interface ExerciseDrawerData {
  componentId: any;
  componentContent: any;
  workoutSection: any;
  exercise: any;
}

export interface CustomExerciseTitleCellEditorProps {
  saveWorkoutContent: (componentId: string, componentContent: any) => void;
  setGridLoader: React.Dispatch<React.SetStateAction<boolean>>;
  handleClickOpenDialog: (event?: any, slug?: string) => void;
}

export const defaultExerciseCellTitles: Record<RowType, string> = {
  // all ComponentType types
  ...defaultComponentTitles,

  // workout types
  [RowType.WORKOUT_SECTION]: defaultSectionTitle,
  [RowType.EXERCISE]: defaultExerciseTitle,
};

export const getDefaultExerciseCellTitle = (rowType: RowType): string =>
  defaultExerciseCellTitles[rowType];

export const isComponentRow = (rowType: RowType) =>
  Object.values(ComponentType).includes(rowType?.toString() as ComponentType);

export const rangeRegex = /^(\d{1,3})\s*-\s*(\d{1,3})$/;
