import clsx from "clsx";
import React, { MouseEventHandler, Suspense, useEffect, useState } from "react";
import {
  Box,
  BoxProps,
  CircularProgress,
  Dialog,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  useProgramSchedule,
  ScheduleUpdate,
} from "../../hooks/useProgramSchedule";
import { ComponentType } from "../../constants";
import { ProgramCalendarWeekProps } from "../program-calendar/ProgramCalendarWeek";
import type { ProgramAddComponentCallback } from "../program/ProgramDetails";

import { useParams, useSearchParams } from "react-router-dom";
import { grey } from "@mui/material/colors";
import { CoachProgramComponentPreviewRoute } from "../../routes/coach/programs/preview/component/CoachProgramComponentPreviewRoute";
import CoachComponentBarLoader from "../program-component/CoachComponentBarLoader";
import { TransitionProps } from "@mui/material/transitions";
import { ProgramSpreadsheetWeek } from "./ProgramSpreadsheetWeek";
import { ExerciseLibraryContext } from "../exercise-library/hooks/useExerciseLibrary";
import { graphql, useLazyLoadQuery } from "react-relay";
import { ClientProgramsProgramComponentRouteQuery } from "../../routes/client/programs/program-component/__generated__/ClientProgramsProgramComponentRouteQuery.graphql";
import {
  PADDING_LEFT_DESKTOP,
  PADDING_LEFT_MOBILE,
  PADDING_RIGHT_DESKTOP,
  PADDING_RIGHT_MOBILE,
} from "../app/AppBar";
import { polyfillCSS } from "../../utils/css";
import {
  SpreadsheetInfoDrawerContext,
  useSpreadsheetInfoDrawer,
} from "../../hooks/useSpreadsheetInfoDrawer";
import { SpreadsheetInfoDrawer } from "./instructions/SpreadsheetInfoDrawer";
import { ColumnField } from "./utils";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: polyfillCSS(`calc(${PADDING_LEFT_MOBILE(theme)} * -1)`),
    marginRight: polyfillCSS(`calc(${PADDING_RIGHT_MOBILE(theme)}  * -1)`),

    [theme.breakpoints.up("sm")]: {
      marginLeft: polyfillCSS(`calc(${PADDING_LEFT_DESKTOP(theme)} * -1)`),
      marginRight: polyfillCSS(`calc(${PADDING_RIGHT_DESKTOP(theme)} * -1)`),
    },
  },
  weeksWrapper: {
    overflowX: "auto",
  },
  weeks: {
    flexDirection: "column",
    width: "100%",

    paddingLeft: theme.spacing(4),

    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(6),
    },

    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gridAutoRows: "auto",
    columnGap: 60,
  },
  week: {
    margin: theme.spacing(3, 0, 4, 0),
  },
}));

export interface ProgramSpreadsheetProps extends BoxProps {
  filters?: ProgramCalendarWeekProps["filters"];
  startDate?: string;
  onAddComponent?: ProgramAddComponentCallback;
  onOpenMenu?: ProgramCalendarWeekProps["onOpenMenu"];
  programRef: any;
  weeks?: any;
  programId?: string;
  activityPreview: boolean;
  setActivityPreview: React.Dispatch<React.SetStateAction<boolean>>;
  duplicateWeekInFlight?: boolean;
  weeksActions: {
    onClickDelete: MouseEventHandler<HTMLElement>;
    onClickMove: MouseEventHandler<HTMLElement>;
    onClickDuplicate: MouseEventHandler<HTMLElement>;
  };
}

export function ProgramSpreadsheet(props: ProgramSpreadsheetProps) {
  const {
    activityPreview,
    setActivityPreview,
    className,
    programRef,
    filters,
    startDate,
    onOpenMenu,
    onAddComponent,
    weeks,
    duplicateWeekInFlight,
    weeksActions,
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();

  const programSlug = params.slug;
  const componentSlugSearchParams = searchParams.get("component");

  const s = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const [scheduleUpdate, setScheduleUpdate] = React.useState<ScheduleUpdate>();

  const [components, schedule] = useProgramSchedule(
    programRef,
    filters,
    scheduleUpdate,
  );

  const [activityPreviewItem, setActivityPreviewItem] = useState<any>();
  const [openDialog, setOpenDialog] = React.useState(false);

  const [infoDrawerColumnField, setInfoDrawerColumnField] =
    useState<ColumnField>();

  // TODO refactor and extract into a single place from spreadsheet and calendar
  const handleClickOpenDialog = (e?: any, slug?: string) => {
    slug && setActivityPreviewItem(slug);
    setSearchParams({
      component: slug ?? componentSlugSearchParams ?? activityPreviewItem,
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSearchParams();
    setOpenDialog(false);
  };

  useEffect(() => {
    if (componentSlugSearchParams && programSlug) {
      setActivityPreview(true);
      setActivityPreviewItem(componentSlugSearchParams);
      handleClickOpenDialog();
    }
  }, []);

  useEffect(() => {
    isMd && setActivityPreview(false);
  }, [isMd]);

  const summary = searchParams.get("summary");

  const exerciseLibraryContextProps =
    useLazyLoadQuery<ClientProgramsProgramComponentRouteQuery>(
      graphql`
        query ProgramSpreadsheetQuery(
          $program: String!
          $date: String!
          $component: String!
          $shouldFetchSummary: Boolean!
        ) {
          activity(client: "$me", date: $date, component: $component) {
            ...Activity_activity
              @arguments(shouldFetchSummary: $shouldFetchSummary)
            component {
              ...RefreshSlug
            }
          }
          ...useExerciseLibrary_rootRef
          program(slug: $program, showArchived: true) {
            id
            ...RefreshSlug
            ...CheckInAnswerProgressPhotoUpload_program
            ...WorkoutSectionElement_program
          }
        }
      `,
      {
        component: "component",
        date: "2021-10-10", // TODO unify magic date with other usages
        program: programRef.id,
        shouldFetchSummary: summary === "yes",
      },
    );

  const spreadsheetWeek = React.useMemo(
    () => (
      <Box className={clsx(s.weeks, className)}>
        {weeks.map((week, i) => (
          <ProgramSpreadsheetWeek
            className={s.week}
            key={week.id}
            weekRef={week}
            schedule={schedule}
            filters={filters}
            programId={props.programId}
            startDate={startDate}
            onOpenMenu={onOpenMenu}
            onAddComponent={(
              weekId: string,
              type: ComponentType,
              days?: boolean[],
              positions?: string[],
              content?: string,
            ) => {
              const callback =
                type === ComponentType.WORKOUT
                  ? undefined
                  : handleClickOpenDialog;
              return onAddComponent(
                weekId,
                type,
                days,
                positions,
                content,
                callback,
              );
            }}
            setActivityPreview={setActivityPreview}
            activityPreviewItem={activityPreview && activityPreviewItem}
            setActivityPreviewItem={setActivityPreviewItem}
            handleClickOpenDialog={handleClickOpenDialog}
            weeksData={{
              canMoveUp: week.week > 1,
              canMoveDown: week.week < programRef.length,
              canDuplicate: duplicateWeekInFlight,
              canDelete: programRef.length > 1,
            }}
            weeksActions={weeksActions}
          />
        ))}
      </Box>
    ),
    [schedule, filters, startDate, duplicateWeekInFlight],
  );

  return (
    <SpreadsheetInfoDrawerContext.Provider
      value={[infoDrawerColumnField, setInfoDrawerColumnField]}
    >
      <ExerciseLibraryContext.Provider value={exerciseLibraryContextProps}>
        <Box className={clsx(s.root, className)}>
          <Box className={s.weeksWrapper}>{spreadsheetWeek}</Box>

          <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            onClose={handleCloseDialog}
            PaperProps={{
              sx: {
                width: "100vw",
                height: "100vh",
                maxHeight: "unset",
                maxWidth: "unset",
                margin: "unset",
              },
            }}
          >
            <Suspense
              fallback={
                <>
                  <CoachComponentBarLoader backClick={handleCloseDialog} />
                  <Box display={"flex"} height={1} color={grey[400]}>
                    <CircularProgress
                      size={20}
                      color={"inherit"}
                      sx={{
                        margin: "auto",
                      }}
                    />
                  </Box>
                </>
              }
            >
              <CoachProgramComponentPreviewRoute
                key={activityPreviewItem}
                componentSlugProp={activityPreviewItem}
                handleCloseDialog={handleCloseDialog}
              />
            </Suspense>
          </Dialog>
          <SpreadsheetInfoDrawer />
        </Box>
      </ExerciseLibraryContext.Provider>
    </SpreadsheetInfoDrawerContext.Provider>
  );
}
