import { CustomCellRendererProps } from "@ag-grid-community/react";
import {
  Typography,
  TypographyProps,
  Box,
  useTheme,
  Menu,
  MenuItem,
  ListSubheader,
  SxProps,
  Divider,
  alpha,
} from "@mui/material";
import React, { useState } from "react";
import { chunk as chunkArray, isEqual } from "lodash";
import {
  ColumnField,
  CustomProgramCellEditorProps,
  rangeRegex,
} from "../utils";
import {
  ExerciseTypeExtra,
  ExerciseTypeSet,
  getExerciseUnits,
  setUnitsTypeOptions,
  setUnitTimeTypeOptions,
  Units,
  UnitsTime,
} from "../../../constants";
import { iconsHoverStyling } from "./DragCell/DragCellRenderer";
import { RowType } from "../../../constants";
import { measurementTypeOptions } from "../../workout/WorkoutExerciseSettings";
import { capitalize } from "lodash";
import { isPositiveNumber } from "../../workout/utils";
import { updateExerciseLocalStorageConfig } from "../../../utils/local-storage";

function getFirstRepetitiveSetIndex(arr) {
  for (let i = 1; i < arr.length; i++) {
    const chunkedArray = chunkArray(arr, i);

    const leftSet = chunkedArray[0];
    for (let j = 1; j < chunkedArray.length; j++) {
      const rightSet = chunkedArray[j];

      if (!isEqual(leftSet.slice(0, rightSet.length), rightSet)) {
        break;
      }

      if (j === chunkedArray.length - 1) {
        return i;
      }
    }
  }

  return arr.length;
}

const VALUES_SEPARATOR = ", ";

const Text = ({
  secondary = false,
  children,
  forwardRef,
  sx,
}: {
  secondary?: boolean;
  children: TypographyProps["children"];
  forwardRef?: any;
  sx?: SxProps;
}) => (
  <Typography
    variant="inherit"
    component="span"
    color={(theme) =>
      secondary ? theme.palette.divider : theme.palette.common.black
    }
    ref={forwardRef}
    sx={{ ...sx }}
  >
    {children}
  </Typography>
);

const SetCellRenderer = (
  props: CustomCellRendererProps & CustomProgramCellEditorProps,
) => {
  const valuesArray = props.value;
  const key = props.colDef.field;

  const [unitsMenuAnchorEl, setUnitsMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const openUnitsDropdownMenu = (event: React.MouseEvent<HTMLElement>) => {
    setUnitsMenuAnchorEl(event.currentTarget);
  };
  const handleUnitChange = (
    typeSet?: ExerciseTypeSet,
    value?: Units | UnitsTime,
  ) => {
    setUnitsMenuAnchorEl(null);

    if (!typeSet || !value) {
      return;
    }

    props.data.typeSet = typeSet;
    props.data.units = value;

    props.handleExerciseUpdate(
      {
        ...props.data.workoutSectionData,
        exercises: props.data.exercises.map((e) => ({
          ...e,
          ...(e.id === props.data.exerciseData.id && { typeSet, units: value }),
        })),
      },
      props.data.componentContent,
      props.data.workoutSectionData.id,
      props.data.componentId,
    );

    updateExerciseLocalStorageConfig({
      typeSet,
      units: value,
    });
  };

  const handleExtraTypeChange = (newValue: ExerciseTypeExtra) => {
    setUnitsMenuAnchorEl(null);

    if (!newValue && !props.data.typeExtraMeasurement) {
      return;
    }

    props.data.typeExtraMeasurement = newValue;

    props.handleExerciseUpdate(
      {
        ...props.data.workoutSectionData,
        exercises: props.data.exercises.map((e) => ({
          ...e,
          ...(e.id === props.data.exerciseData.id && {
            typeExtraMeasurement: newValue,
          }),
        })),
      },
      props.data.componentContent,
      props.data.workoutSectionData.id,
      props.data.componentId,
    );

    updateExerciseLocalStorageConfig({
      typeExtraMeasurement: newValue,
    });
  };

  const theme = useTheme();

  if (props.data.rowType !== RowType.EXERCISE) {
    return <></>;
  }

  const isValueValid = (value: string) => {
    if (props.colDef.field === ColumnField.REPS) {
      const repsRangeRegex = /^\s*(\d{1,3})\s*-\s*(\d{1,3})\s*$/;
      return isPositiveNumber(value) || repsRangeRegex.test(value);
    }

    return isPositiveNumber(value);
  };

  if (
    !valuesArray ||
    !Array.isArray(valuesArray) ||
    valuesArray.filter((v) => isValueValid(v)).length === 0
  ) {
    return <Text secondary>-</Text>;
  }

  const validValuesArray = valuesArray.map((v) => (isValueValid(v) ? v : 0));
  const firstRepetitiveSetIndex = getFirstRepetitiveSetIndex(validValuesArray);

  const sanitizedValuesArray = validValuesArray.map((v) => {
    if (v === 0) {
      return "-";
    }
    const match = v?.match(rangeRegex);
    if (match && match[1] === match[2]) {
      return match[1];
    }
    return v;
  });
  const mainString = sanitizedValuesArray
    .slice(0, firstRepetitiveSetIndex)
    .join(VALUES_SEPARATOR);
  const repetitiveValuesSuffix = sanitizedValuesArray
    .slice(firstRepetitiveSetIndex)
    .join(VALUES_SEPARATOR);

  const getMenuItems = (key: ColumnField, currentValue: any) => {
    switch (key) {
      case ColumnField.WEIGHT:
        return (
          <>
            {Object.values(ExerciseTypeSet).map((typeSet, i) => (
              <>
                {i !== 0 && <Divider style={{ margin: 0 }} />}
                {typeSet === ExerciseTypeSet.TIME
                  ? setUnitTimeTypeOptions.map((item) => {
                      const isSelected =
                        currentValue === getExerciseUnits(typeSet, item.value);
                      return (
                        <MenuItem
                          value={typeSet}
                          onClick={() =>
                            !isSelected && handleUnitChange(typeSet, item.value)
                          }
                          sx={{
                            fontWeight: 500,
                            background: isSelected
                              ? alpha(theme.palette.common.black, 0.04)
                              : "unset",
                            ":hover": {
                              background: alpha(
                                theme.palette.common.black,
                                0.06,
                              ),
                            },
                          }}
                        >
                          <Box
                            sx={{
                              color: theme.palette.divider,
                              fontWeight: "bold",
                              mr: 3,
                            }}
                          >
                            {capitalize(typeSet)}
                          </Box>
                          <Box
                            sx={{
                              textTransform: "lowercase",
                              ml: "auto",
                            }}
                          >
                            {item.labelShortened}
                          </Box>
                        </MenuItem>
                      );
                    })
                  : setUnitsTypeOptions.map((item) => {
                      const isSelected =
                        currentValue === getExerciseUnits(typeSet, item.value);
                      return (
                        <MenuItem
                          value={typeSet}
                          onClick={() =>
                            !isSelected && handleUnitChange(typeSet, item.value)
                          }
                          sx={{
                            fontWeight: 500,
                            background: isSelected
                              ? alpha(theme.palette.common.black, 0.04)
                              : "unset",
                            ":hover": {
                              background: alpha(
                                theme.palette.common.black,
                                0.06,
                              ),
                            },
                          }}
                        >
                          <Box
                            sx={{
                              color: theme.palette.divider,
                              fontWeight: "bold",
                              mr: 3,
                            }}
                          >
                            {capitalize(typeSet)}
                          </Box>
                          <Box
                            sx={{
                              textTransform: "lowercase",
                              ml: "auto",
                            }}
                          >
                            {getExerciseUnits(typeSet, item.value)}
                          </Box>
                        </MenuItem>
                      );
                    })}
              </>
            ))}
          </>
        );
      case ColumnField.EXTRA_MEASUREMENT:
        return (
          <>
            {measurementTypeOptions.map((option) => (
              <MenuItem
                value={option.value}
                onClick={() =>
                  currentValue !== option.value &&
                  handleExtraTypeChange(option.value)
                }
                sx={{
                  fontWeight: 500,
                  background:
                    currentValue === option.value
                      ? alpha(theme.palette.common.black, 0.04)
                      : "unset",
                  ":hover": {
                    background: alpha(theme.palette.common.black, 0.06),
                  },
                  justifyContent: "end",
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </>
        );
      default:
        return <></>;
    }
  };

  const getMenuAnchorElementTitle = (key: ColumnField) => {
    switch (key) {
      case ColumnField.WEIGHT:
        return getExerciseUnits(props.data.typeSet, props.data.units);
      case ColumnField.EXTRA_MEASUREMENT:
        return props.data?.typeExtraMeasurement;
      default:
        return "";
    }
  };

  const getMenuCurrentValue = (key: ColumnField) => {
    switch (key) {
      case ColumnField.WEIGHT:
        return getExerciseUnits(props.data.typeSet, props.data.units);
      case ColumnField.EXTRA_MEASUREMENT:
        return props.data.typeExtraMeasurement;
      default:
        return "";
    }
  };

  return (
    <Box display="flex" gap={0.5}>
      <Box
        sx={{
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: (theme) => theme.palette.divider,
        }}
      >
        <Text>{mainString}</Text>
        {repetitiveValuesSuffix && (
          <Text secondary>{VALUES_SEPARATOR + repetitiveValuesSuffix}</Text>
        )}
      </Box>
      {(key === ColumnField.WEIGHT ||
        key === ColumnField.EXTRA_MEASUREMENT) && (
        <>
          <Box onClick={openUnitsDropdownMenu}>
            <Text
              secondary
              sx={{
                ...iconsHoverStyling(theme, false),
                p: 1,
                borderRadius: 2,
              }}
            >
              {getMenuAnchorElementTitle(key)}
            </Text>
          </Box>
          <Menu
            anchorEl={unitsMenuAnchorEl}
            open={!!unitsMenuAnchorEl}
            onClose={() => handleUnitChange()}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            slotProps={{
              paper: {
                sx: {
                  ml: key === ColumnField.WEIGHT ? "27px" : "34px",
                },
              },
            }}
          >
            {getMenuItems(key, getMenuCurrentValue(key))}
          </Menu>
        </>
      )}
    </Box>
  );
};

export default SetCellRenderer;
