import { useGridCellEditor, CustomCellEditorProps } from "ag-grid-react";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { isPositiveNumber, isValidSetField } from "../../workout/utils";
import { isEqual } from "lodash";
import { CustomProgramCellEditorProps, rangeRegex } from "../utils";
import {
  DefaultExerciseTypeExtra,
  ExerciseTypeExtra,
  ExerciseTypeReps,
} from "../../../constants";
import { getExerciseLocalStorageConfig } from "../../../utils/local-storage";

const useStyles = makeStyles((theme) => ({
  input: {
    paddingInline: theme.spacing(1.8),
    border: "none",
    outline: "none",
    width: "100%",
  },
}));

export default memo(
  ({
    initialValue,
    onValueChange,
    api,
    data,
    rowIndex,
    column,
    colDef,
    handleExerciseUpdate,
  }: CustomCellEditorProps & CustomProgramCellEditorProps) => {
    const s = useStyles();

    const inputRef = useRef<HTMLInputElement>(null);

    const key = colDef.field;

    const initialValueString =
      initialValue
        .map((v) => {
          const match = v?.match(rangeRegex);
          if (match && match[1] === match[2]) {
            return match[1];
          }
          return v;
        })
        .join(", ") || "";
    const [value, setValue] = useState(
      /^[,\s]*$/.test(initialValueString) ? "" : initialValueString,
    );

    const onChange = ({ target: { value } }) => {
      setValue(value);
    };

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    const getRepsType = (v) =>
      rangeRegex.test(v) ? ExerciseTypeReps.RANGE : ExerciseTypeReps.WHOLE;

    const getExtraMeasurementType = (
      valuesArray: any[],
    ): ExerciseTypeExtra | "" => {
      const isEmptyArray =
        valuesArray.filter((v) => isPositiveNumber(v)).length === 0;
      if (isEmptyArray) {
        return "";
      }
      if (data.exerciseData.typeExtraMeasurement) {
        return data.exerciseData.typeExtraMeasurement;
      }
      return (
        getExerciseLocalStorageConfig()?.typeExtraMeasurement ??
        DefaultExerciseTypeExtra
      );
    };

    const isCancelAfterEnd = useCallback(() => {
      const valueArray = Array.isArray(value) ? value : value.split(",") ?? [0];
      const trimmedValueArray = valueArray.map((v) => v.trim());

      if (isEqual(initialValue, trimmedValueArray)) {
        return true;
      }

      const includesRangeValue =
        trimmedValueArray.filter((v) => rangeRegex.test(v)).length > 0;
      const sanitizedValueArray = trimmedValueArray.map((v) => {
        if (includesRangeValue) {
          const separatorIndex = v.indexOf("-");
          if (separatorIndex === -1) {
            return `${v}-${v}`;
          }
          if (separatorIndex === 0) {
            const number = v.substring(1, v.length);
            return `${number}-${number}`;
          }
          if (separatorIndex === v.length - 1) {
            const number = v.substring(0, v.length - 2);
            return `${number}-${number}`;
          }
          const [leftPart, rightPart] = v.split("-").map(Number);
          if (!Number.isNaN(leftPart) && !Number.isNaN(rightPart)) {
            return [
              Math.min(leftPart, rightPart),
              Math.max(leftPart, rightPart),
            ].join("-");
          }
        }
        if (v === "" || v === "0") {
          return "-";
        }
        return v;
      });

      data.typeReps = includesRangeValue
        ? ExerciseTypeReps.RANGE
        : ExerciseTypeReps.WHOLE;

      const isValueArrayValid =
        sanitizedValueArray.filter(
          (v) => !isValidSetField(v, key, getRepsType(v), data.typeSet),
        ).length === 0;

      if (
        !isValueArrayValid ||
        sanitizedValueArray.length > data.exerciseData.sets.length
      ) {
        const rowNode = api!.getDisplayedRowAtIndex(rowIndex)!;
        api.flashCells({
          rowNodes: [rowNode],
          columns: [column],
        });
        return true;
      }

      const finalValueArray = data.exerciseData.sets.map(
        (s, i) => sanitizedValueArray[i % sanitizedValueArray.length],
      );

      if (isEqual(initialValue, finalValueArray)) {
        return true;
      }

      onValueChange(finalValueArray);

      handleExerciseUpdate(
        {
          ...data.workoutSectionData,
          exercises: data.exercises.map((e) => ({
            ...e,
            ...(e.id === data.exerciseData.id
              ? {
                  sets: e.sets.map((s, i) => ({
                    ...s,
                    [key]: finalValueArray[i],
                  })),
                  ...(key === "reps" ? { typeReps: data.typeReps } : {}),
                  ...(key === "extraMeasurement"
                    ? {
                        typeExtraMeasurement:
                          getExtraMeasurementType(sanitizedValueArray),
                      }
                    : {}),
                }
              : {}),
          })),
        },
        data.componentContent,
        data.workoutSectionData.id,
        data.componentId,
      );

      return false;
    }, [value]);

    useGridCellEditor({
      isCancelAfterEnd,
    });

    return (
      <input
        className={s.input}
        ref={inputRef}
        type="text"
        value={value}
        onChange={onChange}
      />
    );
  },
);
