import React from "react";
import { ExampleValueBadge } from "../../info-section/ExampleValueBadge";
import { InfoBox } from "../../info-section/InfoBox";
import { InfoSection } from "../../info-section/InfoSection";

export const RepsValuesInfoSection = () => (
  <InfoSection
    title="Values"
    description={
      <>
        Only whole numeric values allowed in <b>Reps</b> column.
      </>
    }
  >
    <InfoBox title="Number" description="Use exact number for sets.">
      <ExampleValueBadge value="5" />
    </InfoBox>

    <InfoBox title="Range" description="Apply flexible range for sets.">
      <ExampleValueBadge value="4-6" />
    </InfoBox>
  </InfoSection>
);
