import clsx from "clsx";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  BoxProps,
  useTheme,
  lighten,
  darken,
  alpha,
  debounce,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ProgramWeekSchedule } from "../../hooks/useProgramSchedule";
import type { ProgramAddComponentCallback } from "../program/ProgramDetails";
import { Filters } from "../program/ProgramDetailsFilters";

import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import "ag-grid-community/styles/ag-theme-material.css"; // Optional Theme applied to the Data Grid
import {
  ColDef,
  GridApi,
  IRowNode,
  RefreshCellsParams,
  RowDragEndEvent,
  RowDragLeaveEvent,
  RowDragMoveEvent,
  ValueSetterParams,
} from "ag-grid-community";
import ExerciseTitleEditor from "./SpreadsheetCellEditors/ExerciseTitleEditor";
import ExerciseSetTextEditor from "./SpreadsheetCellEditors/ExerciseSetTextEditor";
import { getColorByComponentType } from "../program-calendar/utils/common";
import {
  ComponentStatus,
  ComponentType,
  RowType,
  SOMETHING_WENT_WRONG,
} from "../../constants";
import { WorkoutDrawer } from "../workout/WorkoutDrawer";
import DragCellRenderer from "./SpreadsheetCellRenderers/DragCell/DragCellRenderer";
import { colorSystem } from "../../theme";
import { isSupersetAtIndex, orderedExercises } from "../workout/utils";
import IconCellRenderer from "./SpreadsheetCellRenderers/IconCellRenderer";
import { graphql } from "relay-runtime";
import { useDirtyMutation } from "../dirty-transaction/hooks";
import { WeekCardUpdateOrderMutation } from "../program-week/__generated__/WeekCardUpdateOrderMutation.graphql";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { WorkoutSection } from "../workout/types";
import { useEnableColors } from "../../hooks/useEnableColors";
import { fixLegacyContent } from "../editor/utils/common";
import SetCellRenderer from "./SpreadsheetCellRenderers/SetCellRenderer";
import NoRowsOverlay from "./NoRowsOverlay/NoRowsOverlay";
import {
  ColumnField,
  ExerciseDrawerData,
  getDefaultExerciseCellTitle,
} from "./utils";
import ExerciseSetsNumberEditor from "./SpreadsheetCellEditors/ExerciseSetsNumberEditor";
import ExerciseTextEditor from "./SpreadsheetCellEditors/ExerciseTextEditor";
import { ProgramSpreadsheetGridComponentContentMutation } from "./__generated__/ProgramSpreadsheetGridComponentContentMutation.graphql";
import { capitalize, isEqual } from "lodash";
import CustomHeaderGroup from "./SpreadsheetCellRenderers/CustomHeaderGroup";
import InfoHeader from "./HeaderCellRenderers/InfoHeader";
import { useSpreadsheetInfoDrawer } from "../../hooks/useSpreadsheetInfoDrawer";
import ExerciseCellRenderer from "./SpreadsheetCellRenderers/ExerciseCellRenderer";
import { generateId } from "../new-editor/utils/nodeUtil";
import PublishButton from "./publish/PublishButton";

export const DRAG_CELL_WIDTH = 35;
export const ICON_CELL_WIDTH = 50;
export const EXERCISE_CELL_WIDTH = 320;
export const SETS_CELL_WIDTH = 65;
export const REPS_CELL_WIDTH = 135;
export const UNIT_CELL_WIDTH = 140;
export const RPE_CELL_WIDTH = 140;

const MIN_RESIZABLE_COL_WIDTH_SMALL = 60;
const MIN_RESIZABLE_COL_WIDTH_MEDIUM = 110;
const MIN_RESIZABLE_COL_WIDTH_LARGE = 150;

let potentialDragTarget: any = null;
let draggedRowIndex: number | null = null;
let numberOfDraggedRows: number | null = null;

function getNumberOfDraggedItems(api: GridApi, draggedNode: IRowNode) {
  if (!draggedNode) {
    return 0;
  }

  let numberOfDraggedItems = 1;
  let nextRow = draggedNode;
  if (draggedNode.data.rowType === RowType.WORKOUT) {
    do {
      const prevRow = nextRow;
      nextRow = api.getDisplayedRowAtIndex(nextRow.rowIndex + 1);

      if (
        (prevRow?.data.rowType === RowType.EXERCISE ||
          prevRow?.data.rowType === RowType.WORKOUT_SECTION) &&
        nextRow?.data.rowType !== RowType.EXERCISE &&
        nextRow?.data.rowType !== RowType.WORKOUT_SECTION
      ) {
        break;
      }
      numberOfDraggedItems++;
    } while (nextRow);
  }
  if (draggedNode.data.rowType === RowType.WORKOUT_SECTION) {
    do {
      const prevRow = nextRow;
      nextRow = api.getDisplayedRowAtIndex(nextRow.rowIndex + 1);

      if (
        (prevRow?.data.rowType === RowType.EXERCISE &&
          nextRow?.data.rowType !== RowType.EXERCISE) ||
        (prevRow?.data.rowType !== RowType.EXERCISE &&
          nextRow?.data.rowType !== RowType.EXERCISE)
      ) {
        break;
      }
      numberOfDraggedItems++;
    } while (nextRow);
  }
  return numberOfDraggedItems;
}

function setPotentialParentForNode(
  api: GridApi,
  overNode: IRowNode | undefined | null,
  node: IRowNode | undefined | null,
) {
  let newPotentialDragTarget;
  draggedRowIndex = node?.rowIndex;
  let isPotentialDragTargetValid: boolean = false;

  const findMatchingRow = (
    condition: (node: IRowNode<any>) => boolean,
    api: GridApi,
    findFirstMatchingNode: boolean,
    returnPreviousMatchingNode: boolean = false,
  ) => {
    let previousNode = null;
    let previousMatchingNode = null;
    let foundNode = null;

    api.forEachNode((node) => {
      if (condition(node) && !(findFirstMatchingNode && foundNode)) {
        previousMatchingNode = previousNode;
        foundNode = node;
      }
      previousNode = node;
    });

    return returnPreviousMatchingNode ? previousMatchingNode : foundNode;
  };

  if (node) {
    numberOfDraggedRows = getNumberOfDraggedItems(api, node);
  }

  if (overNode) {
    if (node.data.rowType === RowType.WORKOUT_SECTION) {
      let nextRow = overNode;
      isPotentialDragTargetValid = false;
      newPotentialDragTarget = overNode;
      do {
        const prevRow = nextRow;
        nextRow = api.getDisplayedRowAtIndex(nextRow.rowIndex + 1);

        if (
          (prevRow?.data.rowType === RowType.WORKOUT_SECTION &&
            nextRow?.data.rowType !== RowType.EXERCISE) ||
          prevRow?.data.rowType === RowType.WORKOUT ||
          (prevRow?.data.rowType === RowType.EXERCISE &&
            nextRow?.data.rowType !== RowType.EXERCISE)
        ) {
          newPotentialDragTarget = prevRow;
          isPotentialDragTargetValid = true;
          break;
        }
      } while (nextRow);

      if (!isPotentialDragTargetValid) {
        if (overNode.rowIndex < node.rowIndex) {
          newPotentialDragTarget = findMatchingRow(
            (node) => node.data.rowType === RowType.WORKOUT,
            api,
            true,
          );
        } else {
          newPotentialDragTarget = findMatchingRow(
            (node) => node.data.rowType === RowType.EXERCISE,
            api,
            false,
          );
        }
      }
    } else if (node.data.rowType === RowType.WORKOUT) {
      let nextRow = overNode;
      isPotentialDragTargetValid = false;
      newPotentialDragTarget = overNode;
      do {
        const prevRow = nextRow;
        nextRow = api.getDisplayedRowAtIndex(nextRow.rowIndex + 1);

        if (
          ((prevRow?.data.rowType === RowType.EXERCISE ||
            prevRow?.data.rowType === RowType.WORKOUT_SECTION) &&
            nextRow?.data.rowType !== RowType.EXERCISE &&
            nextRow?.data.rowType !== RowType.WORKOUT_SECTION) ||
          nextRow?.data.rowType === RowType.WORKOUT
        ) {
          newPotentialDragTarget = prevRow;
          isPotentialDragTargetValid = true;
          break;
        }
      } while (nextRow);

      if (!isPotentialDragTargetValid) {
        if (overNode.rowIndex < node.rowIndex) {
          newPotentialDragTarget = findMatchingRow(
            (node) => node.data.rowType === RowType.WORKOUT,
            api,
            true,
            true,
          );
        } else {
          newPotentialDragTarget = findMatchingRow(
            (node) => node.data.rowType === RowType.EXERCISE,
            api,
            false,
          );
        }
      }
    } else if (node.data.rowType === RowType.EXERCISE) {
      isPotentialDragTargetValid =
        node.data.rowType === overNode.data.rowType ||
        (overNode.data.rowType === RowType.WORKOUT_SECTION &&
          node.data.rowType === RowType.EXERCISE);

      if (isPotentialDragTargetValid) {
        newPotentialDragTarget = overNode;
      } else {
        if (overNode.rowIndex < node.rowIndex) {
          newPotentialDragTarget = findMatchingRow(
            (node) => node.data.rowType === RowType.WORKOUT_SECTION,
            api,
            true,
            false,
          );
        } else {
          newPotentialDragTarget = findMatchingRow(
            (node) =>
              node.data.rowType === RowType.WORKOUT_SECTION ||
              node.data.rowType === RowType.EXERCISE,
            api,
            false,
          );
        }
      }
    } else {
      isPotentialDragTargetValid = node.data.rowType === overNode.data.rowType;
      if (isPotentialDragTargetValid) {
        newPotentialDragTarget = overNode;
      } else {
        if (overNode.rowIndex < node.rowIndex) {
          newPotentialDragTarget = findMatchingRow(
            (potentialNode) => potentialNode.data.rowType === node.data.rowType,
            api,
            true,
            true,
          );
        } else {
          newPotentialDragTarget = findMatchingRow(
            (potentialNode) => potentialNode.data.rowType === node.data.rowType,
            api,
            false,
          );
        }
      }
    }
  } else {
    newPotentialDragTarget = null;
  }
  const alreadySelected = potentialDragTarget === newPotentialDragTarget;
  if (alreadySelected) {
    return;
  }

  // we refresh the previous selection (if it exists) to clear
  // the highlighted and then the new selection.
  const rowsToRefresh = [];
  if (potentialDragTarget) {
    rowsToRefresh.push(potentialDragTarget);
  }
  if (newPotentialDragTarget) {
    rowsToRefresh.push(newPotentialDragTarget);
  }
  potentialDragTarget = newPotentialDragTarget;
  refreshRows(api, rowsToRefresh);
}

function refreshRows(api: GridApi, rowsToRefresh: IRowNode[]) {
  const params: RefreshCellsParams = {
    // refresh these rows only.
    rowNodes: rowsToRefresh,
    // because the grid does change detection, the refresh
    // will not happen because the underlying value has not
    // changed. to get around this, we force the refresh,
    // which skips change detection.
    force: true,
  };
  api.refreshCells(params);
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",

    "& .ag-theme-quartz": {
      "--ag-active-color": colorSystem.slateGray,
      "--ag-header-height": "35px",
      "--ag-font-family": "'Montserrat',sans-serif",
      // at the moment flash is triggered manually with api.flashCells to indicate validation error
      "--ag-value-change-value-highlight-background-color":
        theme.palette.error.main,
    },

    "& .border-l-slate-900": {
      "--tw-border-opacity": "0.1 !important",
    },

    "& .ag-cell": {
      borderTop: "solid 1px transparent",
      borderBottom: "solid 1px transparent",
      transitionProperty: "background, background-color !important",
      "&:focus-within": { zIndex: 1 },
    },

    "& .ag-row:last-child .ag-cell:not(.ag-cell-focus:focus-within)": {
      borderBottomColor: alpha(colorSystem.slateGray, 0.2),
    },

    "& .ag-cell-value": {
      textOverflow: "ellipsis",
      overflow: "hidden",
    },

    "& .ag-root-wrapper": {
      borderRadius: 0,
      border: "none",
    },

    "& .ag-overlay-loading-center": {
      border: "none",
      background: "none",
      boxShadow: "none",
      color: theme.palette.text.disabled,
    },

    "& .ag-root.ag-layout-normal, .ag-root.ag-layout-auto-height": {
      width: "auto",
      paddingRight: 2,
    },

    "& .ag-header-cell-resize": {
      height: "500vmax",
      width: 4,
      right: -4,
      zIndex: 1,
      borderLeft: `1px solid ${colorSystem.white} !important`,
    },

    "& .ag-ltr .ag-header-viewport .ag-header-cell-resize": {
      "&::after": {
        content: '""',
        background: "inherit",
        height: 36,
        top: 0,
        left: -2,
        width: 3,
        position: "absolute",
        transition: theme.transitions.create(["background"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },

      "&:hover::after": {
        background: theme.palette.primary.main,
      },
    },

    "& .ag-header.ag-header-allow-overflow .ag-header-row .ag-header-cell:nth-child(2)":
      {
        border: "none",
        background: "white",
      },

    "& .ag-header .ag-header-viewport": {
      overflow: "visible",
      background: "white",
    },

    "& .ag-header": {
      overflow: "visible",
      border: "none",
    },

    "& .ag-row, & .ag-row *": {
      transition: theme.transitions.create([
        "background",
        "background-color",
        "border",
      ]),
    },

    "& .ag-root:has(.ag-row-dragging) .drag-cell-renderer": {
      display: "none",
    },

    "& .ag-root:has(.ag-row-dragging) .ag-row": {
      pointerEvents: "none",
    },

    "& .dragging": {
      opacity: 0.5,
    },

    "& .ag-row:has(.potentialDragTarget)::before": {
      content: '""',
      position: "absolute",
      display: "block",
      width: "100%",
      height: 5,
      bottom: -2,
      backgroundColorOpacity: "0.2",
      borderBottom: "2px dashed !important",
      marginLeft: `${DRAG_CELL_WIDTH}px`,
      zIndex: theme.zIndex.mobileStepper,
      top: "unset",
      backgroundColor: "unset",
    },

    "& .ag-row:has(.potentialDragTarget):has(.isPotentialDragTargetValid)::before":
      {
        borderBottom: "2px dashed !important",
        marginLeft: `${DRAG_CELL_WIDTH}px`,
        zIndex: theme.zIndex.mobileStepper,
      },

    "& .ag-center-cols-viewport": {
      marginBottom: 12,
      overflow: "visible",
    },

    "& .ag-body .ag-body-viewport": {
      overflow: "visible",
    },

    "& .ag-body-horizontal-scroll": {
      display: "none",
      visibility: "hidden",
    },

    "& .ag-react-container": {
      pointerEvents: "all",
      border: "1px solid",
      borderColor: theme.palette.divider,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: DRAG_CELL_WIDTH,
    },

    "& .ag-ltr .ag-body": {
      minHeight: 200,
    },

    "& .ag-header-cell": {
      overflow: "visible",
      border: "unset !important",
    },
  },

  gridStyle: {
    height: "100%",
    marginLeft: theme.spacing(-4.4),
    marginTop: theme.spacing(2),
  },
  workoutRow: {
    "&::before": {
      transition: theme.transitions.create(["background-color"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: colorSystem.primary,
    },
  },
  workoutRowMonochrome: {
    "&::before": {
      transition: theme.transitions.create(["background-color"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: darken(theme.palette.common.white, 0.075),
    },
  },
  workout: {
    border: "unset !important",
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      left: 0,
      width: "3px",
      height: "var(--before-height)",
      zIndex: theme.zIndex.appBar,
    },
  },
  readonlySetsCell: {
    color: alpha(theme.palette.common.black, 0.3),
    cursor: "not-allowed",
  },
  exerciseCell: {
    display: "flex",
    justifyContent: "space-between",
  },
  defaultExerciseCell: {
    fontStyle: "italic",
    color: alpha(theme.palette.common.black, 0.3),
  },
  dayHeaderCell: {
    padding: "unset !important",
    fontWeight: "bold",
    textAlign: "center",
    "& .ag-header-cell-text": { width: "100%" },
    "& .ag-header-cell-resize": {
      cursor: "default",
      "&:hover::after": {
        background: "unset !important",
      },
    },
  },
  setsHeaderCell: {
    padding: "unset !important",

    "& .ag-header-cell-label": {
      justifyContent: "center",
    },
  },
  infoHeaderCell: {
    padding: "unset !important",
  },
  noEdit: {
    cursor: "not-allowed",
  },
  publishContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

export interface ProgramSpreadsheetGridProps extends BoxProps {
  filters?: Filters;
  onAddComponent?: ProgramAddComponentCallback;
  schedule: ProgramWeekSchedule;
  dayOfWeek: number;
  positions: string[];
  week: number;
  weekId: string;
  programId: string;
  weekRef: any;
  setActivityPreview: React.Dispatch<React.SetStateAction<boolean>>;
  activityPreviewItem?: any;
  setActivityPreviewItem: React.Dispatch<React.SetStateAction<string>>;
  handleClickOpenDialog: (event?: any, slug?: string) => void;
  duplicateByDirection: {
    up: ProgramWeekSchedule;
    down: ProgramWeekSchedule;
    left: ProgramWeekSchedule;
    right: ProgramWeekSchedule;
  };
}

const updateOrderMutation = graphql`
  mutation ProgramSpreadsheetGridUpdateOrderMutation(
    $input: UpdateWeekComponentsOrderInput!
  ) {
    updateWeekComponentsOrder(input: $input) {
      week {
        positions
      }
    }
  }
`;

const updateComponentContentMutation = graphql`
  mutation ProgramSpreadsheetGridComponentContentMutation(
    $input: UpsertComponentInput!
  ) {
    upsertComponent(input: $input) {
      component {
        ...DragCellRenderer_component
      }
    }
  }
`;

export function ProgramSpreadsheetGrid(props: ProgramSpreadsheetGridProps) {
  const {
    filters,
    schedule,
    dayOfWeek,
    handleClickOpenDialog,
    week,
    weekId,
    programId,
    onAddComponent,
    positions,
    duplicateByDirection,
  } = props;

  const theme = useTheme();
  const s = useStyles();
  const enableColors = useEnableColors();

  const [gridLoader, setGridLoader] = useState(false);

  const [updateComponentContent] =
    useDirtyMutation<ProgramSpreadsheetGridComponentContentMutation>(
      updateComponentContentMutation,
    );

  const [updateOrder] =
    useDirtyMutation<WeekCardUpdateOrderMutation>(updateOrderMutation);

  const cellClass = "border border-b-0 border-t-0 border-white rounded-none]";

  const [exerciseDrawerData, setExerciseDrawerData] =
    useState<ExerciseDrawerData>();
  const [infoDrawerColumnField, setInfoDrawerColumnField] =
    useSpreadsheetInfoDrawer();

  const borderStyle = "1.2px solid " + alpha(colorSystem.slateGray, 0.2);

  const isPartOfWorkoutRow = (rowType: RowType) =>
    [RowType.WORKOUT, RowType.WORKOUT_SECTION, RowType.EXERCISE].includes(
      rowType,
    );

  const publishComponent = (
    componentId: string,
    componentContent: any,
    componentTitle: string,
  ) => {
    const input = {
      id: componentId,
      content: JSON.stringify(componentContent),
      title: componentTitle,
      status: ComponentStatus.PUBLISHED,
    };
    setGridLoader(true);
    updateComponentContent({
      variables: {
        input,
      },
      optimisticResponse: {
        upsertComponent: {
          component: input,
        },
      },
      onCompleted: (_, errors) => {
        if (errors) {
          setGridLoader(false);
          snackAlert({
            severity: "error",
            message: errors?.[0]?.message || SOMETHING_WENT_WRONG,
          });
        } else {
          snackAlert({
            severity: "success",
            message: "Published successfully",
          });
        }
      },
    });
  };

  const saveWorkoutContent = (
    componentId: string,
    componentContent: any,
    // TODO find better way to handle this after migration to REST API
    componentTitle?: string,
  ) => {
    const input = {
      id: componentId,
      content: JSON.stringify(componentContent),
    };
    setGridLoader(true);
    updateComponentContent({
      variables: {
        input,
      },
      optimisticResponse: {
        upsertComponent: {
          component: input,
        },
      },
      onCompleted: (_, errors) => {
        // due to mutation conflict we need to split filtering and publish to separate request
        componentTitle &&
          publishComponent(componentId, componentContent, componentTitle);

        if (errors) {
          setGridLoader(false);
          snackAlert({
            severity: "error",
            message: errors?.[0]?.message || SOMETHING_WENT_WRONG,
          });
        } else {
          snackAlert({
            severity: "success",
            message: "Component data updated",
          });
        }
      },
    });
  };

  const saveNewSets = (params: ValueSetterParams) => {
    params.data.sets = params.newValue;
    return true;
  };

  const setTextCellEditorSelector = (params) => {
    if (params.data.rowType === RowType.EXERCISE) {
      return {
        component: ExerciseSetTextEditor,
        params: {
          handleExerciseUpdate: handleExerciseUpdate,
        },
      };
    }

    return {
      component: "agTextCellEditor",
    };
  };

  const setsNumberCellEditorSelector = (params) => {
    if (params.data.rowType === RowType.EXERCISE) {
      return {
        component: ExerciseSetsNumberEditor,
        params: {
          handleExerciseUpdate: handleExerciseUpdate,
        },
      };
    }

    return {
      component: "agTextCellEditor",
    };
  };

  const baseColumnDef = {
    suppressMovable: true,
  };
  const [columnWidths, setColumnWidths] = useState({});

  const DEFAULT_WEIGHT_HEADING = "Weight";
  const [dynamicWeightHeader, setDynamicWeightHeader] = useState(
    DEFAULT_WEIGHT_HEADING,
  );
  const DEFAULT_EXTRA_TYPE_HEADING = "RPE/RIR";
  const [dynamicExtraHeader, setDynamicExtraHeader] = useState(
    DEFAULT_EXTRA_TYPE_HEADING,
  );

  const updateColumnWidths = useCallback(
    debounce((updatedWidths) => {
      setColumnWidths(updatedWidths);
    }, 300),
    [],
  );

  const onColumnResized = (event) => {
    if (event.column && event.column.getColId()) {
      const colId = event.column.getColId();
      const newWidth = event.column.getActualWidth();
      const updatedWidths = {
        ...columnWidths,
        [colId]: newWidth,
      };
      updateColumnWidths(updatedWidths);
    }
  };

  const showPublishButton = (props: any) => {
    const { rowType, status, draftExists } = props.data;

    return (
      rowType !== RowType.WORKOUT_SECTION &&
      (status === ComponentStatus.DRAFT || draftExists)
    );
  };

  const columnDefs = useMemo(() => {
    return [
      {
        ...baseColumnDef,
        field: ColumnField.DRAG,
        headerName: "",
        resizable: false,
        width: columnWidths[ColumnField.DRAG] || DRAG_CELL_WIDTH,
        editable: false,
        cellClass:
          "bg-white !border-none flex justify-center !overflow-visible",
        cellRenderer: (props) => {
          return DragCellRenderer({
            ...props,
            componentRef: schedule?.find(
              (s) => s.component?.id === props.data.componentId,
            )?.component,
            onAddComponent,
            day: dayOfWeek,
            weekId,
            positions,
            filters,
            saveWorkoutContent,
            setExerciseDrawerData,
            setGridLoader,
          });
        },
      },
      {
        ...baseColumnDef,
        field: ColumnField.ICON,
        headerName: "Day " + (dayOfWeek + 1),
        resizable: true,
        minWidth: ICON_CELL_WIDTH,
        maxWidth: ICON_CELL_WIDTH,
        width: columnWidths[ColumnField.ICON] || ICON_CELL_WIDTH,
        editable: false,
        headerClass: s.dayHeaderCell,
        cellClass: (props) => {
          return clsx(
            "border border-b-transparent border-t-transparent flex justify-center border-l-transparent !overflow-visible !p-0",
            isPartOfWorkoutRow(props.data.rowType) &&
              (enableColors ? s.workoutRow : s.workoutRowMonochrome),
            props.data.rowType === RowType.WORKOUT_SECTION && s.noEdit,
            props.data.rowType === RowType.WORKOUT && s.workout,
          );
        },
        cellStyle: (props) => {
          const isWorkout = props.data.rowType === RowType.WORKOUT;

          let workoutSections = 0;
          let totalExercises = 0;
          if (isWorkout) {
            props.data.componentContent.forEach((item) => {
              if (item.type === "workout_section") {
                workoutSections += 1;

                if (item.workout && Array.isArray(item.workout.exercises)) {
                  totalExercises += item.workout.exercises.length;
                }
              }
            });
          }
          const workoutLineHeight =
            42 + workoutSections * 35 + totalExercises * 42 - 1 + "px";

          return isWorkout ? { "--before-height": `${workoutLineHeight}` } : {};
        },
        cellRenderer: (props) => {
          return IconCellRenderer({
            ...props,
            handleExerciseUpdate,
          });
        },
      },
      {
        ...baseColumnDef,
        field: ColumnField.EXERCISE,
        headerName: "Exercise",
        cellRenderer: (params) => {
          return ExerciseCellRenderer({
            ...params,
            setInfoDrawerColumnField,
            exerciseDrawerData,
            setExerciseDrawerData,
            handleClickOpenDialog,
          });
        },
        cellEditorSelector: (params) => {
          if (params.data.rowType === RowType.EXERCISE) {
            return {
              component: ExerciseTitleEditor,
              params: {
                handleExerciseUpdate: handleExerciseUpdate,
                setExerciseDrawerData: setExerciseDrawerData,
                paramsData: params.data,
              },
            };
          }

          return {
            component: ExerciseTextEditor,
            params: {
              saveWorkoutContent,
              setGridLoader,
              handleClickOpenDialog,
            },
          };
        },
        cellClass: (props) => {
          return clsx(
            cellClass + " flex",
            s.exerciseCell,
            props.data.exercise ===
              getDefaultExerciseCellTitle(props.data.rowType) &&
              s.defaultExerciseCell,
          );
        },
        width: columnWidths[ColumnField.EXERCISE] || EXERCISE_CELL_WIDTH,
        minWidth: MIN_RESIZABLE_COL_WIDTH_LARGE,
      },
      {
        ...baseColumnDef,
        field: ColumnField.SETS,
        headerName: "Sets",
        valueSetter: saveNewSets,
        cellEditor: "agNumberCellEditor",
        cellEditorParams: {
          min: 0.001,
        },
        width: columnWidths[ColumnField.SETS] || SETS_CELL_WIDTH,
        minWidth: MIN_RESIZABLE_COL_WIDTH_SMALL,
        cellEditorSelector: setsNumberCellEditorSelector,
        cellClass: (props) => {
          return clsx(
            cellClass,
            "text-center",
            props.data.rowType !== RowType.EXERCISE && s.readonlySetsCell,
          );
        },
        editable: (params) => params.data.rowType === RowType.EXERCISE,
        headerClass: s.setsHeaderCell,
      },
      {
        ...baseColumnDef,
        field: ColumnField.REPS,
        headerName: "Reps",
        width: columnWidths[ColumnField.REPS] || REPS_CELL_WIDTH,
        minWidth: MIN_RESIZABLE_COL_WIDTH_MEDIUM,
        cellEditorSelector: setTextCellEditorSelector,
        cellClass: (props) => {
          return clsx(
            cellClass,
            props.data.rowType !== RowType.EXERCISE && s.noEdit,
          );
        },
        editable: (params) => params.data.rowType === RowType.EXERCISE,
        cellRenderer: (props) => {
          return SetCellRenderer({
            ...props,
            handleExerciseUpdate,
          });
        },
        headerComponent: InfoHeader,
        headerClass: s.infoHeaderCell,
      },
      {
        ...baseColumnDef,
        field: ColumnField.WEIGHT,
        headerName: dynamicWeightHeader,
        width: columnWidths[ColumnField.WEIGHT] || UNIT_CELL_WIDTH,
        minWidth: MIN_RESIZABLE_COL_WIDTH_LARGE,
        cellEditorSelector: setTextCellEditorSelector,
        cellClass: (props) => {
          return clsx(
            cellClass,
            props.data.rowType !== RowType.EXERCISE && s.noEdit,
          );
        },
        editable: (params) => params.data.rowType === RowType.EXERCISE,
        cellRenderer: (props) => {
          return SetCellRenderer({
            ...props,
            handleExerciseUpdate,
          });
        },
        headerComponent: InfoHeader,
        headerClass: s.infoHeaderCell,
      },
      {
        ...baseColumnDef,
        field: ColumnField.EXTRA_MEASUREMENT,
        headerName: dynamicExtraHeader,
        width: columnWidths[ColumnField.EXTRA_MEASUREMENT] || RPE_CELL_WIDTH,
        minWidth: MIN_RESIZABLE_COL_WIDTH_LARGE,
        cellEditorSelector: setTextCellEditorSelector,
        cellClass: (props) => {
          return clsx(
            cellClass,
            props.data.rowType !== RowType.EXERCISE && s.publishContainer,
            !showPublishButton(props) && s.noEdit,
          );
        },
        editable: (params) => params.data.rowType === RowType.EXERCISE,
        cellRenderer: (props) => {
          if (props.data.rowType === RowType.EXERCISE) {
            return SetCellRenderer({
              ...props,
              handleExerciseUpdate,
            });
          }
          if (showPublishButton(props)) {
            return (
              <PublishButton
                componentId={props.data.componentId}
                componentContent={props.data.componentContent}
                exercise={props.data.exercise}
                saveWorkoutContent={saveWorkoutContent}
              />
            );
          }
        },
        headerComponent: InfoHeader,
        headerClass: s.infoHeaderCell,
      },
    ];
  }, [
    schedule,
    enableColors,
    exerciseDrawerData,
    dynamicWeightHeader,
    dynamicExtraHeader,
    columnWidths,
  ]);

  const defaultColDef: ColDef = {
    sortable: false,
    editable: true,
    cellClass: "border border-l-1 border-t-0 border-white",
    cellClassRules: {
      potentialDragTarget: (params) => {
        return (
          params.node === potentialDragTarget &&
          draggedRowIndex &&
          draggedRowIndex !== potentialDragTarget.rowIndex &&
          draggedRowIndex - 1 !== potentialDragTarget.rowIndex &&
          numberOfDraggedRows + draggedRowIndex - 1 !==
            potentialDragTarget.rowIndex
        );
      },
      dragging: (params) => {
        const colIndex = params.api
          ?.getColumnDefs()
          ?.findIndex(
            (col) => (col as any).field === (params.column as any).colId,
          );
        return (
          colIndex !== 0 &&
          draggedRowIndex &&
          params.node.rowIndex >= draggedRowIndex &&
          params.node.rowIndex < numberOfDraggedRows + draggedRowIndex
        );
      },
    },
  };

  const filterWorkoutSections = (sections: any) => {
    return sections?.filter((item) => item.type === "workout_section");
  };

  const gridRef = useRef<any>(null);
  const snackAlert = useSnackAlert();

  const findMostFrequentItem = (arr: any[], key: string): any => {
    const itemCount = new Map<any, number>();
    let mostFrequentItem: any;
    let highestCount = 0;

    arr.forEach((item) => {
      const currentKey = item[key];
      if (currentKey === undefined) return;

      const currentCount = (itemCount.get(currentKey) ?? 0) + 1;
      itemCount.set(currentKey, currentCount);
      if (currentCount > highestCount) {
        highestCount = currentCount;
        mostFrequentItem = currentKey;
      }
    });

    return mostFrequentItem;
  };

  const setGridData = (api: GridApi) => {
    const data = [];

    data[dayOfWeek] = [];

    const calculateTotalWorkoutSectionSets = (workoutSection: any): number =>
      workoutSection?.workout?.exercises?.reduce(
        (sum, exercise) =>
          sum +
          (exercise?.sets && Array.isArray(exercise?.sets)
            ? exercise.sets.length
            : 0),
        0,
      ) ?? 0;

    const calculateTotalWorkoutSets = (workoutSections: any): number =>
      workoutSections?.reduce(
        (sum, ws) => sum + calculateTotalWorkoutSectionSets(ws),
        0,
      ) ?? 0;

    let prevComponentType: string | null = null;
    for (const w of schedule.filter((w) => !!w.days[dayOfWeek])) {
      if (w.component.type === "WORKOUT") {
        const componentContent = fixLegacyContent(
          JSON.parse(w.component.content),
        );
        const workoutSections = filterWorkoutSections(componentContent);
        data[dayOfWeek].push({
          componentId: w.component.id,
          slug: w.component.slug,
          iconName: w.component.iconName,
          type: w.component.type,
          rowType: RowType.WORKOUT,
          exercise: w.component.title,
          sets: calculateTotalWorkoutSets(workoutSections),
          componentContent: componentContent,
          status: w.component.status,
          draftExists: w.component.draftExists,
          prevComponentType,
        });
        for (const ws of workoutSections) {
          data[dayOfWeek].push({
            componentId: w.component.id,
            // TODO take the content by component id where needed?
            componentContent: componentContent,
            slug: w.component.slug,
            iconName: w.component.iconName,
            type: w.component.type,
            rowType: RowType.WORKOUT_SECTION,
            exercise: ws.workout?.title
              ? ws.workout.title
              : getDefaultExerciseCellTitle(RowType.WORKOUT_SECTION),
            sets: calculateTotalWorkoutSectionSets(ws),
            reps: "",
            workoutSectionData: ws,
            status: w.component.status,
            draftExists: w.component.draftExists,
            prevComponentType,
          });
          const exercises = ws.workout?.exercises;
          if (!exercises) {
            continue;
          }
          for (const [index, e] of exercises.entries()) {
            data[dayOfWeek].push({
              componentId: w.component.id,
              // TODO take the content by component id where needed?
              componentContent: componentContent,
              slug: w.component.slug,
              iconName: w.component.iconName,
              type: w.component.type,
              rowType: RowType.EXERCISE,
              exercise: e.title
                ? e.title
                : getDefaultExerciseCellTitle(RowType.EXERCISE),
              exerciseActive: isSupersetAtIndex(exercises, index),
              sets: e.sets.length,
              reps: e.sets.map((s) => s.reps),
              typeReps: e.typeReps,
              weight: e.sets.map((s) => s.weight),
              typeSet: e.typeSet,
              units: e.units,
              extraMeasurement: e.sets.map((s) => s.extraMeasurement),
              typeExtraMeasurement: e.typeExtraMeasurement,
              workoutSectionData: ws,
              exerciseData: e,
              status: w.component.status,
              draftExists: w.component.draftExists,
              index: index,
              exercises: exercises,
              prevComponentType,
            });
          }
        }
      } else {
        data[dayOfWeek].push({
          componentId: w.component.id,
          slug: w.component.slug,
          iconName: w.component.iconName,
          type: w.component.type,
          rowType: w.component.type,
          exercise: w.component.title,
          // sets: "",
          reps: "",
          status: w.component.status,
          draftExists: w.component.draftExists,
          prevComponentType,
        });
      }
      prevComponentType = w.component.type;
    }

    const noData = data[dayOfWeek].length === 0;

    const itemsWithExtraMeasurement = data[dayOfWeek].filter(
      (item) => item?.typeExtraMeasurement,
    );

    const newExtraMeasurementHeading = noData
      ? DEFAULT_EXTRA_TYPE_HEADING
      : findMostFrequentItem(itemsWithExtraMeasurement, "typeExtraMeasurement");

    const itemsWithWeight = data[dayOfWeek].filter(
      (item) => item?.typeSet && item.weight.some((w) => w !== ""),
    );

    const newWeightHeading = noData
      ? DEFAULT_WEIGHT_HEADING
      : capitalize(findMostFrequentItem(itemsWithWeight, "typeSet"));

    const updatedColumnDefs = api.getColumnDefs()?.map((colDef: any) => {
      const headingMap: Record<string, string> = {
        [ColumnField.WEIGHT]: newWeightHeading,
        [ColumnField.EXTRA_MEASUREMENT]: newExtraMeasurementHeading,
      };

      const heading = headingMap[colDef.field] || colDef.headerName;

      return { ...colDef, headerName: heading };
    });

    newWeightHeading && setDynamicWeightHeader(newWeightHeading);
    newExtraMeasurementHeading &&
      setDynamicExtraHeader(newExtraMeasurementHeading);

    updatedColumnDefs && api.setGridOption("columnDefs", updatedColumnDefs);
    api.setGridOption("rowData", data[dayOfWeek]);
    setGridLoader(false);
  };

  const handleExerciseUpdate = (
    workout: WorkoutSection,
    content?,
    wsId?,
    id?,
    onCompleteCallback?,
  ) => {
    // TODO check on exercise title update
    const componentContent = exerciseDrawerData?.componentContent ?? content;
    const workoutSectionId = exerciseDrawerData?.workoutSection.id ?? wsId;
    const componentId = exerciseDrawerData?.componentId ?? id;

    componentContent.map((node) => {
      if (node.id === workoutSectionId) {
        node.workout.exercises = workout.exercises;
      }
    });

    const input = {
      id: componentId,
      content: JSON.stringify(componentContent),
    };
    setGridLoader(true);
    updateComponentContent({
      variables: {
        input,
      },
      optimisticResponse: {
        upsertComponent: {
          component: input,
        },
      },
      onCompleted: (_, errors) => {
        onCompleteCallback && onCompleteCallback();
        if (errors) {
          setGridLoader(false);
          snackAlert({
            severity: "error",
            message: SOMETHING_WENT_WRONG,
          });
        } else {
          snackAlert({
            severity: "success",
            message: "Workout updated",
          });
        }
      },
    });
  };

  const getRowStyle = useCallback(
    (params: any) => {
      const isFirstRow = params.node.rowIndex === 0;

      const borderBottomStyle = "1px solid white";
      let borderTopStyle = "none";
      if (isFirstRow) {
        borderTopStyle = borderStyle;
      }

      if (params.data.rowType === RowType.WORKOUT_SECTION) {
        const color = enableColors
          ? getColorByComponentType(ComponentType.WORKOUT, 0.3)
          : darken(theme.palette.common.white, 0.05);

        return {
          background: `linear-gradient(to right, white 35px, ${color} 35px)`,
          borderRight: borderStyle,
          borderBottom: borderBottomStyle,
        };
      }

      const color = enableColors
        ? getColorByComponentType(
            (params.data.rowType as ComponentType) ?? ComponentType.WORKOUT,
            0.65,
          )
        : params.data.rowType === RowType.EXERCISE
          ? theme.palette.common.white
          : darken(theme.palette.common.white, 0.1);

      return {
        backgroundColor: `linear-gradient(to right, white 35px, ${color} 35px)`,
        borderRight: borderStyle,
        borderBottom: borderBottomStyle,
        background:
          params.data.status === ComponentStatus.ARCHIVED
            ? `repeating-linear-gradient(
              45deg,
              ${color} 0px,
              ${color} 5px,
              ${lighten(color === "unset" ? theme.palette.common.white : color, 0.3)} 5px,
              ${lighten(color === "unset" ? theme.palette.common.white : color, 0.3)} 10px
            )`
            : `linear-gradient(to right, white 35px, ${color} 35px)`,
      };
    },
    [enableColors],
  );

  const onRowDragMove = useCallback((event: RowDragMoveEvent) => {
    setPotentialParentForNode(event.api, event.overNode, event.node);
    event.api.refreshCells();
  }, []);

  const onRowDragLeave = useCallback((event: RowDragLeaveEvent) => {
    setPotentialParentForNode(event.api, null, event.node);
  }, []);

  const onRowDragEnd = useCallback(
    (event: RowDragEndEvent) => {
      if (!potentialDragTarget) {
        setPotentialParentForNode(event.api, null, null);
        return;
      }

      const prev = event.api.getGridOption("rowData");
      if (
        !potentialDragTarget ||
        potentialDragTarget.rowIndex === event.node.rowIndex
      ) {
        return;
      }
      const draggedNode = event.node.data;
      const draggedIndex = event.node.rowIndex;
      const dayData = [...prev];

      const numberOfDraggedItems = getNumberOfDraggedItems(
        event.api,
        event.node,
      );
      const draggedItems = dayData.splice(draggedIndex, numberOfDraggedItems);
      const newIndex =
        potentialDragTarget.rowIndex > draggedIndex
          ? potentialDragTarget.rowIndex - numberOfDraggedItems + 1
          : potentialDragTarget.rowIndex + 1;
      dayData.splice(newIndex, 0, ...draggedItems);

      if (draggedNode.rowType === RowType.EXERCISE) {
        let ind = 0;
        for (let i = 1; i < dayData.length; i++) {
          if (
            dayData[i].rowType === RowType.EXERCISE &&
            dayData[i - 1].rowType !== RowType.EXERCISE
          ) {
            ind = 0;
          }
          dayData[i].index = ind;
          if (dayData[i].rowType === RowType.EXERCISE) {
            const orderedExercisesData = orderedExercises(
              dayData[i].workoutSectionData.workout?.exercises,
            );
            dayData[i].exerciseActive =
              ind < orderedExercisesData.length &&
              isSupersetAtIndex(orderedExercisesData, ind);
          }
          ind++;
        }
      }

      gridRef.current.api.setGridOption("rowData", dayData);

      setTimeout(() => {
        if (draggedNode.rowType === RowType.WORKOUT_SECTION) {
          if (
            draggedNode.componentId === potentialDragTarget.data.componentId
          ) {
            const componentContent = draggedNode.componentContent;
            const originalComponentContent = JSON.stringify(componentContent);
            const firstIndex = componentContent.findIndex(
              (item) => item.id === draggedNode.workoutSectionData.id,
            );

            const secondIndex = potentialDragTarget.data.workoutSectionData?.id
              ? componentContent.findIndex(
                  (item) =>
                    item.id === potentialDragTarget.data.workoutSectionData.id,
                )
              : -1;

            const [itemToMove] = componentContent.splice(firstIndex, 1);
            const newSecondIndex =
              secondIndex > firstIndex ? secondIndex - 1 : secondIndex;
            componentContent.splice(newSecondIndex + 1, 0, itemToMove);

            const updatedComponentContent = JSON.stringify(componentContent);
            const hasReordered =
              originalComponentContent !== updatedComponentContent;

            const input = {
              id: draggedNode.componentId,
              content: updatedComponentContent,
            };
            hasReordered && setGridLoader(true);
            hasReordered &&
              updateComponentContent({
                variables: {
                  input,
                },
                optimisticResponse: {
                  upsertComponent: {
                    component: input,
                  },
                },
                onCompleted: (_, errors) => {
                  if (errors) {
                    setGridLoader(false);
                    snackAlert({
                      severity: "error",
                      message: SOMETHING_WENT_WRONG,
                    });
                  } else {
                    snackAlert({
                      severity: "success",
                      message: "Workout section updated",
                    });
                  }
                },
              });
          } else {
            draggedNode.componentContent = draggedNode.componentContent.filter(
              (item) => item.id !== draggedNode.workoutSectionData.id,
            );
            const input = {
              id: draggedNode.componentId,
              content: JSON.stringify(draggedNode.componentContent),
            };
            setGridLoader(true);
            updateComponentContent({
              variables: {
                input,
              },
              optimisticResponse: {
                upsertComponent: {
                  component: input,
                },
              },
              onCompleted: (_, errors) => {
                if (errors) {
                  setGridLoader(false);
                  snackAlert({
                    severity: "error",
                    message: SOMETHING_WENT_WRONG,
                  });
                }
              },
            });
            const secondIndex = potentialDragTarget.data.workoutSectionData?.id
              ? potentialDragTarget.data.componentContent.findIndex(
                  (item) =>
                    item.id === potentialDragTarget.data.workoutSectionData.id,
                )
              : -1;
            potentialDragTarget.data.componentContent ??= [];
            potentialDragTarget.data.componentContent.splice(
              secondIndex + 1,
              0,
              draggedNode.workoutSectionData,
            );

            potentialDragTarget.data.componentContent = fixLegacyContent(
              potentialDragTarget.data.componentContent,
            );
            const input2 = {
              id: potentialDragTarget.data.componentId,
              content: JSON.stringify(
                potentialDragTarget.data.componentContent,
              ),
            };
            setGridLoader(true);
            updateComponentContent({
              variables: {
                input: input2,
              },
              optimisticResponse: {
                upsertComponent: {
                  component: input2,
                },
              },
              onCompleted: (_, errors) => {
                if (errors) {
                  setGridLoader(false);
                  snackAlert({
                    severity: "error",
                    message: SOMETHING_WENT_WRONG,
                  });
                }
              },
            });
          }
        } else if (draggedNode.rowType === RowType.EXERCISE) {
          if (
            draggedNode.componentId === potentialDragTarget.data.componentId
          ) {
            const componentContent = draggedNode.componentContent;
            const firstWSIndex = componentContent.findIndex(
              (item) => item.id === draggedNode.workoutSectionData.id,
            );
            const secondWSIndex = componentContent.findIndex(
              (item) =>
                item.id === potentialDragTarget.data.workoutSectionData.id,
            );

            const indexOfFirstExercise = componentContent[
              firstWSIndex
            ].workout.exercises.findIndex(
              (item) => item.id === draggedNode.exerciseData.id,
            );

            let indexOfSecondExercise = -1;

            if (potentialDragTarget.data.exerciseData?.id) {
              indexOfSecondExercise = componentContent[
                secondWSIndex
              ].workout.exercises.findIndex(
                (item) => item.id === potentialDragTarget.data.exerciseData.id,
              );
            }

            const [itemToMove] = componentContent[
              firstWSIndex
            ].workout.exercises.splice(indexOfFirstExercise, 1);

            itemToMove.superset = generateId();

            const newSecondExerciseIndex =
              indexOfSecondExercise > indexOfFirstExercise &&
              firstWSIndex === secondWSIndex
                ? indexOfSecondExercise - 1
                : indexOfSecondExercise;

            componentContent[secondWSIndex].workout.exercises.splice(
              newSecondExerciseIndex + 1,
              0,
              itemToMove,
            );
            if (indexOfFirstExercise !== -1) {
              componentContent[firstWSIndex].workout.exercises[
                indexOfFirstExercise
              ].superset = generateId();
            }

            const input = {
              id: draggedNode.componentId,
              content: JSON.stringify(componentContent),
            };
            setGridLoader(true);
            updateComponentContent({
              variables: {
                input,
              },
              optimisticResponse: {
                upsertComponent: {
                  component: input,
                },
              },
              onCompleted: (_, errors) => {
                if (errors) {
                  setGridLoader(false);
                  snackAlert({
                    severity: "error",
                    message: SOMETHING_WENT_WRONG,
                  });
                } else {
                  snackAlert({
                    severity: "success",
                    message: "Workout section updated",
                  });
                }
              },
            });
          } else {
            draggedNode.componentContent = draggedNode.componentContent.map(
              (ws) => {
                if (ws.id === draggedNode.workoutSectionData.id) {
                  ws.workout.exercises = ws.workout.exercises.filter(
                    (item) => item.id !== draggedNode.exerciseData.id,
                  );
                }
                return ws;
              },
            );

            const input = {
              id: draggedNode.componentId,
              content: JSON.stringify(draggedNode.componentContent),
            };
            setGridLoader(true);
            updateComponentContent({
              variables: {
                input,
              },
              optimisticResponse: {
                upsertComponent: {
                  component: input,
                },
              },
              onCompleted: (_, errors) => {
                if (errors) {
                  setGridLoader(false);
                  snackAlert({
                    severity: "error",
                    message: SOMETHING_WENT_WRONG,
                  });
                }
              },
            });

            const secondWS = potentialDragTarget.data.componentContent.find(
              (item) =>
                item.id === potentialDragTarget.data.workoutSectionData.id,
            );

            const secondIndex = potentialDragTarget.data.exerciseData?.id
              ? secondWS.workout.exercises.findIndex(
                  (item) =>
                    item.id === potentialDragTarget.data.exerciseData.id,
                )
              : -1;

            secondWS.workout.exercises.splice(
              secondIndex + 1,
              0,
              draggedNode.exerciseData,
            );

            potentialDragTarget.data.componentContent = fixLegacyContent(
              potentialDragTarget.data.componentContent,
            );
            const input2 = {
              id: potentialDragTarget.data.componentId,
              content: JSON.stringify(
                potentialDragTarget.data.componentContent,
              ),
            };
            setGridLoader(true);
            updateComponentContent({
              variables: {
                input: input2,
              },
              optimisticResponse: {
                upsertComponent: {
                  component: input2,
                },
              },
              onCompleted: (_, errors) => {
                if (errors) {
                  setGridLoader(false);
                  snackAlert({
                    severity: "error",
                    message: SOMETHING_WENT_WRONG,
                  });
                }
              },
            });
          }
        } else {
          const newPositions = [
            ...new Set(dayData.map((item) => item.componentId)),
          ];
          if (!isEqual(positions, newPositions)) {
            setGridLoader(true);
            updateOrder({
              variables: {
                input: {
                  programId: programId,
                  week: week,
                  positions: [
                    ...new Set(dayData.map((item) => item.componentId)),
                  ],
                },
              },

              onCompleted() {
                snackAlert({
                  severity: "success",
                  message: "Components order updated",
                });
              },

              onError() {
                setGridLoader(false);
                snackAlert({
                  severity: "error",
                  message: "Couldn't update components order",
                });
              },
            });
          }
        }
        setPotentialParentForNode(event.api, null, null);
      }, 0);

      draggedRowIndex = null;
    },
    [potentialDragTarget, positions],
  );

  useEffect(() => {
    if (gridRef.current?.api) {
      setGridData(gridRef.current.api);
    }
  }, [schedule]);

  const rowDragText = (params) => {
    return `${params.rowNode.data.exercise} ${
      params.rowNode.data.sets ? ` + ${params.rowNode.data.sets} sets` : ""
    }`;
  };

  const getRowHeight = (params) => {
    if (params.data.rowType === RowType.WORKOUT_SECTION) {
      return 35;
    }
    return 42;
  };

  const noRowsOverlayComponentParams = {
    weekId,
    dayOfWeek,
    onAddComponent,
    duplicateByDirection,
    gridLoader,
    setGridLoader,
    filters,
  };

  const agGrid = React.useMemo(
    () => (
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        domLayout={"autoHeight"}
        defaultColDef={defaultColDef}
        getRowStyle={getRowStyle}
        rowClass={"group"}
        onRowDragMove={onRowDragMove}
        onRowDragLeave={onRowDragLeave}
        onRowDragEnd={onRowDragEnd}
        rowSelection="multiple"
        suppressRowTransform={true}
        onGridReady={(params) => setGridData(params.api)}
        rowDragText={rowDragText}
        getRowHeight={getRowHeight}
        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
        noRowsOverlayComponent={NoRowsOverlay}
        onCellFocused={({ api, rowIndex }) => {
          const focusedCell = api.getFocusedCell();
          if (!focusedCell) {
            return;
          }

          const rowNode = api.getDisplayedRowAtIndex(rowIndex);
          if (!focusedCell.column.isCellEditable(rowNode)) {
            api.clearFocusedCell();
          }
        }}
        onColumnResized={onColumnResized}
        stopEditingWhenCellsLoseFocus
      />
    ),
    [enableColors, schedule, noRowsOverlayComponentParams, gridLoader],
  );

  return (
    <>
      <Box
        className={s.root}
        sx={{ pointerEvents: gridLoader ? "none" : "all" }}
      >
        {gridLoader && <CustomHeaderGroup />}
        <div className={clsx(s.gridStyle, "ag-theme-quartz")}>{agGrid}</div>
      </Box>
      <WorkoutDrawer
        section={exerciseDrawerData?.workoutSection.workout}
        exercise={exerciseDrawerData?.exercise}
        onClose={() => setExerciseDrawerData(undefined)}
        onTitleChange={() => {}}
        onUpdate={(workout) => {
          handleExerciseUpdate(workout);
          setExerciseDrawerData(undefined);
        }}
        onCancel={() => setExerciseDrawerData(undefined)}
        onRemove={() => {}}
        open={Boolean(exerciseDrawerData)}
      />
    </>
  );
}
