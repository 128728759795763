import React from "react";
import { ExampleValueBadge } from "../../info-section/ExampleValueBadge";
import { InfoBox } from "../../info-section/InfoBox";
import { InfoSection } from "../../info-section/InfoSection";

export const ExtraMeasurementValuesInfoSection = () => {
  return (
    <InfoSection
      title="Values"
      description={
        <>
          Float numbers are allowed in <b>RPE</b> / <b>RIR</b> column.
        </>
      }
    >
      <InfoBox title="Number" description="Apply exact number for sets.">
        <ExampleValueBadge value="10" unit="RPE" />
      </InfoBox>
    </InfoSection>
  );
};
