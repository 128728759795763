import clsx from "clsx";
import React from "react";
import {
  Drawer,
  DrawerProps,
  IconButton,
  Button,
  Box,
  ClickAwayListener,
  Portal,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as CloseIcon } from "../../icons/Close.svg";
import { ReactComponent as BackIcon } from "../../icons/ArrowBack.svg";
import { ReactComponent as RemoveIcon } from "../../icons/Bin.svg";

import { WorkoutSection, WorkoutExercise, ExerciseId } from "./types";
import { WorkoutEditExercise } from "./WorkoutEditExercise";
import { WorkoutEditSection } from "./WorkoutEditSection";
import {
  createEmptyExercise,
  defaultSectionTitle,
  DEFAULT_VALUE_OF_EXERCISE_REPS,
  DEFAULT_NUMBER_OF_EXERCISE_SETS,
} from "./utils";
import { WorkoutTitle } from "./WorkoutTitle";
import { WORKOUT_SECTION_HIGHLIGHT_CONTAINER_CLASS } from "../container/HighlightContainer";
import {
  CommonElementId,
  ExerciseTypeExtra,
  Units,
  UnitsTime,
} from "../../constants";
import { isEqual } from "lodash";
import { updateExerciseLocalStorageConfig } from "../../utils/local-storage";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    zIndex: "99999 !important" as any,
  },

  paper: {
    padding: theme.spacing(3, 4),
    width: "100%",

    "& h2": {
      fontSize: 32,
      margin: theme.spacing(2, 0),
    },

    [theme.breakpoints.up("md")]: {
      maxWidth: theme.spacing(65),
      minWidth: theme.spacing(50),
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  header: {
    margin: theme.spacing(0, 0, 3, 0),
  },

  buttons: {
    display: "flex",
    alignItems: "center",

    "& > :last-child": {
      marginLeft: "auto",
      display: "flex",
      alignItems: "center",
    },
  },

  title: {
    marginBottom: theme.spacing(2),
    cursor: "pointer",
  },

  titleText: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "32px",
  },

  headerButton: {
    color: theme.palette.primary.main,
    borderRadius: 4,
    fontSize: 16,
    fontWeight: 500,

    "& svg": {
      width: 24,
      height: 24,
    },
  },

  backButton: {
    marginLeft: theme.spacing(-2),
  },

  closeButton: {
    marginRight: theme.spacing(-2),
  },

  addButton: {
    borderRadius: 4,
    borderStyle: "solid",
    borderColor: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: "bold",
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
}));

export interface WorkoutDrawerProps extends DrawerProps {
  section?: WorkoutSection;
  exercise?: WorkoutExercise;
  onSelectExercise?: (exercise: WorkoutExercise) => void;
  onUpdate?: (workout: WorkoutSection) => void;
  onCancel?: () => void;
  onRemove?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    exercise?: WorkoutExercise,
  ) => void;
  onTitleChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    title: string,
  ) => void;
}

export function WorkoutDrawer(props: WorkoutDrawerProps) {
  const {
    className,
    section = { exercises: [] } as WorkoutSection,
    exercise: selectedExercise,
    onSelectExercise: setSelectedExercise,
    onTitleChange,
    onUpdate,
    onCancel,
    onRemove,
    ...other
  } = props;
  const { onClose } = props;
  const s = useStyles();

  const handleUpdate = React.useCallback(
    (workout: WorkoutSection) => {
      if (onUpdate) {
        onUpdate(workout);
      }
    },
    [onUpdate],
  );

  const handleUpdateExercise = React.useCallback(
    (exercise: WorkoutExercise) => {
      const exercises = [...section.exercises];
      const index = exercises?.findIndex((it) => it.id === exercise.id);

      if (index !== -1) {
        if (isEqual(exercises[index], exercise)) {
          onClose(event, "backdropClick");
          return;
        }
        exercises[index] = exercise;
      } else {
        exercises?.push(exercise);
      }

      handleUpdate({
        ...section,
        exercises,
      });
      setSelectedExercise && setSelectedExercise(null);

      updateExerciseLocalStorageConfig({
        typeReps: exercise?.typeReps,
        typeSet: exercise?.typeSet,
        units: exercise?.units as Units | UnitsTime,
        typeExtraMeasurement:
          exercise?.typeExtraMeasurement ?? ExerciseTypeExtra.NONE,
      });
    },
    [handleUpdate, section, setSelectedExercise],
  );

  const handleUpdateWorkout = React.useCallback(
    (workout: WorkoutSection) => {
      handleUpdate(workout);
    },
    [handleUpdate],
  );

  const handleCancelExercise = React.useCallback(() => {
    setSelectedExercise && setSelectedExercise(null);

    onCancel && onCancel();
  }, [setSelectedExercise]);

  const handleEditExercise = React.useCallback(
    (exerciseId: ExerciseId) => {
      const exercise = section.exercises?.find((it) => it.id === exerciseId);
      setSelectedExercise({ ...createEmptyExercise(), ...exercise });
    },
    [section.exercises, setSelectedExercise],
  );

  const handleRemoveExercise = React.useCallback(
    (exerciseId: ExerciseId) => {
      handleUpdate({
        ...section,
        exercises: section.exercises?.filter((it) => it.id !== exerciseId),
      });

      handleClose(undefined);
    },
    [handleUpdate, section],
  );

  const handleBack = React.useCallback(() => {
    setSelectedExercise(null);
  }, [setSelectedExercise]);

  const handleClose = React.useCallback(
    (event) => {
      onClose(event, "backdropClick");
    },
    [onClose],
  );

  const handleRemove = React.useCallback(
    (event) => {
      if (selectedExercise) {
        handleBack();
      } else {
        handleClose(event);
      }

      onRemove(event, selectedExercise);
    },
    [handleBack, handleClose, onRemove, selectedExercise],
  );

  const handleAddExercise = React.useCallback(() => {
    setSelectedExercise(
      createEmptyExercise(
        DEFAULT_NUMBER_OF_EXERCISE_SETS,
        DEFAULT_VALUE_OF_EXERCISE_REPS,
      ),
    );
  }, [setSelectedExercise]);

  const canRemove =
    !selectedExercise || section?.exercises?.includes(selectedExercise);

  const handleClickAway = React.useCallback(
    (event) => {
      const el = event.target as HTMLDivElement;

      if (el && "closest" in el) {
        const closestWorkoutSection = el.closest(
          `.${WORKOUT_SECTION_HIGHLIGHT_CONTAINER_CLASS}`,
        );
        const withinMainRoot = el.closest("#root");
        const withinComponentDialog = el.closest(
          "#" + CommonElementId.COMPONENT_DIALOG,
        );

        if (
          withinMainRoot ||
          (withinComponentDialog && !closestWorkoutSection)
        ) {
          handleClose(event);
        }
      }
    },
    [handleClose],
  );

  return (
    <Portal>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Drawer
          className={clsx(s.root, className)}
          classes={{ paper: s.paper }}
          anchor="right"
          variant="persistent"
          {...other}
        >
          <Box className={s.buttons}>
            {selectedExercise && setSelectedExercise && (
              <IconButton
                className={clsx(s.headerButton, s.backButton)}
                onClick={handleBack}
                size="large"
              >
                <BackIcon />
              </IconButton>
            )}

            <Box>
              <IconButton
                className={s.headerButton}
                onClick={
                  selectedExercise
                    ? () => handleRemoveExercise(selectedExercise.id)
                    : handleRemove
                }
                size="large"
              >
                <RemoveIcon />
              </IconButton>

              <IconButton
                className={clsx(s.headerButton, s.closeButton)}
                onClick={handleClose}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          {!selectedExercise && (
            <WorkoutTitle
              classes={{ root: s.title, text: s.titleText }}
              value={section.title}
              onChange={onTitleChange}
              defaultValue={defaultSectionTitle}
              placeholder={defaultSectionTitle}
            />
          )}

          {selectedExercise ? (
            <WorkoutEditExercise
              key={selectedExercise.id}
              sectionName={section.title}
              exercise={selectedExercise}
              onUpdate={handleUpdateExercise}
              onCancel={handleCancelExercise}
            />
          ) : (
            <WorkoutEditSection
              section={section}
              onUpdate={handleUpdateWorkout}
              onEditExercise={handleEditExercise}
              onRemoveExercise={handleRemoveExercise}
            >
              <Button
                variant="outlined"
                className={s.addButton}
                onClick={handleAddExercise}
                fullWidth
              >
                Add exercise
              </Button>
            </WorkoutEditSection>
          )}
        </Drawer>
      </ClickAwayListener>
    </Portal>
  );
}
