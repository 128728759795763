import clsx from "clsx";
import React, { MouseEventHandler } from "react";
import { Box, BoxProps, Typography, Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "relay-runtime";
import { useFragment } from "react-relay/hooks";

import { ReactComponent as EllipsisHorizontalIcon } from "../../icons/EllipsisHorizontal.svg";
import { ProgramWeekSchedule } from "../../hooks/useProgramSchedule";
import type { ProgramAddComponentCallback } from "../program/ProgramDetails";
import { Filters } from "../program/ProgramDetailsFilters";
import { ProgramWeekDateRange } from "../program/ProgramWeekDateRange";

import { ProgramSpreadsheetWeek_week$key } from "./__generated__/ProgramSpreadsheetWeek_week.graphql";
import { ProgramSpreadsheetGrid } from "./ProgramSpreadsheetGrid";
import WeekActionItems from "../program-week/WeekActionItems";

const useStyles = makeStyles((theme) => ({
  root: {},

  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    flexFlow: "row nowrap",
    top: 0,
    background: "white",
    gridColumn: "span 8",
    width: "100%",
    marginLeft: theme.spacing(0.75),
    marginTop: theme.spacing(3),
  },
  header: {
    display: "flex",
    width: "max-content",
    position: "sticky",
    top: theme.spacing(3.5),
    left: theme.spacing(3.5),
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 0,
  },

  dateRange: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.common.black,
  },

  moreButton: {
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "none",
    },
    "& svg path[stroke]": {
      stroke: theme.palette.primary.main,
    },

    "& svg path[fill]": {
      fill: theme.palette.primary.main,
    },
  },

  grid: {
    display: "flex",
    gap: 60,
  },
}));

const fragment = graphql`
  fragment ProgramSpreadsheetWeek_week on Week {
    id
    week
    description
    positions
  }
`;

export interface ProgramSpreadsheetWeekProps extends BoxProps {
  weekRef: ProgramSpreadsheetWeek_week$key;
  filters?: Filters;
  startDate?: string;
  onAddComponent?: ProgramAddComponentCallback;
  onOpenMenu?: ButtonProps["onClick"];
  programId: string;
  schedule: ProgramWeekSchedule;
  setActivityPreview: React.Dispatch<React.SetStateAction<boolean>>;
  activityPreviewItem?: any;
  setActivityPreviewItem: React.Dispatch<React.SetStateAction<string>>;
  handleClickOpenDialog: () => void;
  weeksData: {
    canMoveUp: boolean;
    canMoveDown: boolean;
    canDuplicate: boolean;
    canDelete: boolean;
  };
  weeksActions: {
    onClickDelete: MouseEventHandler<HTMLElement>;
    onClickMove: MouseEventHandler<HTMLElement>;
    onClickDuplicate: MouseEventHandler<HTMLElement>;
  };
}

export function ProgramSpreadsheetWeek(props: ProgramSpreadsheetWeekProps) {
  const {
    className,
    weekRef,
    schedule,
    filters,
    startDate,
    onOpenMenu,
    onAddComponent,
    setActivityPreview,
    activityPreviewItem,
    setActivityPreviewItem,
    handleClickOpenDialog,
    weeksData,
    weeksActions,
    ...other
  } = props;
  const s = useStyles();
  const week = useFragment(fragment, weekRef);
  const daysOfWeek = Array.from({ length: 7 }, (_, i) => i);
  const weekNumber = week.week;

  // PER ROW DUPLICATION LOGIC
  function findClosestDayIndex(
    weekItems: ProgramWeekSchedule,
    inputDayOfWeekIndex: number,
  ): number | null {
    let closestDayIndex: number | null = null;
    let minDistance = Infinity;

    for (const item of weekItems) {
      for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
        if (item.days[dayIndex]) {
          const distance = Math.abs(dayIndex - inputDayOfWeekIndex);
          if (distance < minDistance) {
            minDistance = distance;
            closestDayIndex = dayIndex;
          }
        }
      }
    }

    return closestDayIndex;
  }

  function findItemsWithDay(
    weekItems: ProgramWeekSchedule,
    dayIndex: number,
  ): ProgramWeekSchedule {
    return weekItems.filter((item) => item.days[dayIndex]);
  }

  function getItemsForPerRowDuplication(
    arr: ProgramWeekSchedule,
    dayOfWeek: number,
  ): ProgramWeekSchedule {
    const closestDayIndex = findClosestDayIndex(arr, dayOfWeek);

    if (closestDayIndex !== null) {
      const itemsWithClosestDay = findItemsWithDay(arr, closestDayIndex);
      return itemsWithClosestDay;
    }
    return null;
  }

  // PER COLUMN DUPLICATION LOGIC
  function findClosestWeekIndex(
    weekItems: ProgramWeekSchedule,
    inputWeek: number,
  ): number | null {
    let closestWeek: number | null = null;
    let minDistance = Infinity;

    for (const item of weekItems) {
      for (const week of item.weeks) {
        const distance = Math.abs(week - inputWeek);
        if (distance < minDistance) {
          minDistance = distance;
          closestWeek = week;
        }
      }
    }

    return closestWeek;
  }

  function findItemsWithWeek(
    weekItems: ProgramWeekSchedule,
    closestWeek: number,
  ): ProgramWeekSchedule {
    return weekItems.filter((item) => item.weeks.includes(closestWeek));
  }

  function getItemsForPerColumnDuplication(
    arr: ProgramWeekSchedule,
    weekNumber: number,
  ): ProgramWeekSchedule {
    const closestWeek = findClosestWeekIndex(arr, weekNumber);
    if (closestWeek !== null) {
      const itemsWithClosestWeek = findItemsWithWeek(arr, closestWeek);
      return itemsWithClosestWeek;
    }
    return null;
  }

  return (
    <>
      <Box className={s.headerWrapper}>
        <Box className={s.header}>
          <Typography variant="h5" className={s.title}>
            Week {week.week}
          </Typography>
          <WeekActionItems
            weekId={week.id}
            onClickDelete={weeksActions.onClickDelete}
            onClickMove={weeksActions.onClickMove}
            onClickDuplicate={weeksActions.onClickDuplicate}
            canMoveUp={weeksData.canMoveUp}
            canMoveDown={weeksData.canMoveDown}
            canDuplicate={weeksData.canDuplicate}
            canDelete={weeksData.canDelete}
          />
        </Box>
        {startDate && (
          <ProgramWeekDateRange
            variant="h6"
            className={s.dateRange}
            startDate={startDate}
            week={week.week}
          />
        )}
      </Box>
      {daysOfWeek.map((dayOfWeek) => {
        const currentWeek = schedule.filter((s) => s.weeks.includes(week.week));

        const currentDaySchedule = schedule.filter(
          (s) => s.weeks.includes(week.week) && !!s.days[dayOfWeek],
        );

        const itemsToDuplicateFromCurrentWeek = currentWeek.filter((item) =>
          item.days.some((value, index) => index !== dayOfWeek && value),
        );

        const itemsToDuplicateFromLeft = itemsToDuplicateFromCurrentWeek.filter(
          (item) =>
            item.days.some((value, index) => index > dayOfWeek && value),
        );

        const itemsToDuplicateFromRight =
          itemsToDuplicateFromCurrentWeek.filter((item) =>
            item.days.some((value, index) => index < dayOfWeek && value),
          );

        const currentDayOfWeekArr = schedule.filter(
          (s) => s.days[dayOfWeek] === true,
        );

        const itemsToDuplicateFromAbove = currentDayOfWeekArr.filter((item) =>
          item.weeks.some((w) => w > weekNumber),
        );

        const itemsToDuplicateFromBelow = currentDayOfWeekArr.filter((item) =>
          item.weeks.some((w) => w < weekNumber),
        );

        const duplicateByDirection = {
          down: getItemsForPerColumnDuplication(
            itemsToDuplicateFromAbove,
            weekNumber,
          ),
          up: getItemsForPerColumnDuplication(
            itemsToDuplicateFromBelow,
            weekNumber,
          ),
          right: getItemsForPerRowDuplication(
            itemsToDuplicateFromLeft,
            dayOfWeek,
          ),
          left: getItemsForPerRowDuplication(
            itemsToDuplicateFromRight,
            dayOfWeek,
          ),
        };

        return (
          <Box key={dayOfWeek}>
            <ProgramSpreadsheetGrid
              filters={filters}
              onAddComponent={onAddComponent}
              schedule={currentDaySchedule}
              dayOfWeek={dayOfWeek}
              positions={[...week.positions]}
              week={week.week}
              weekId={week.id}
              programId={props.programId}
              weekRef={weekRef}
              setActivityPreview={setActivityPreview}
              activityPreviewItem={activityPreviewItem}
              setActivityPreviewItem={setActivityPreviewItem}
              handleClickOpenDialog={handleClickOpenDialog}
              duplicateByDirection={duplicateByDirection}
            />
          </Box>
        );
      })}
      {/* Extra column to get free spacing on the right in overflow-x parent container */}
      <Box sx={{ minWidth: "1px", flexShrink: 0 }} />
    </>
  );
}
